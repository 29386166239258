<template>
  <div>
    <TopManager />
    <div class="content with-marge-bottom">
      <div class="top-tools">
        <button
          v-if="!open"
          v-show="$store.state.user.user.role === 3 || $store.state.user.user.role === 7"
          class="btn btn-default"
          style="padding: 11px 20px;width: 200px;flex-shrink: 0;margin-right: 20px;"
          @click="$modal.show('openShift')"
        >
          {{ $t('Касири') }} ({{ $t('відкрито') }} - {{ cashiers.filter(e => e.status === 1).length }})
        </button>
        <button
          v-if="open"
          class="btn btn-green"
          style="padding: 11px 20px;width: 200px;flex-shrink: 0;margin-right: 20px;"
          @click="closeShift"
        >
          {{ $t('Закрити зміну') }}
        </button>
        <input
          type="search"
          class="custom-input"
          :placeholder="$t('Пошук')"
          @input="filterItem"
        >
      </div>
      <div
        v-if="articles"
        class="paydesk-wr"
      >
        <div
          v-for="(person, index) in articles"
          :key="person.id"
          class="one-pay new"
        >
          <div class="number-count before-border">
            <div class="new-pay-t bold hide-old">
              {{ $t('Новий') }}
            </div>
            <div class="caption-grey black bold hide-new">
              {{ $t('Рахунок') }}
            </div>
            <div class="counter hide-new">
              {{ person.id }}
            </div>
            <div class="caption-grey hide-new">
              {{$t('Нал.')}}
            </div>
            <div class="date-time black hide-new">
              {{ formatDate(person.createdate) }}
            </div>
          </div>
          <div class="client-wr before-border">
            <div class="name-client">
              <div class="surname bold">
                {{ person.patient !== null ? person.patient.lastname : '--' }}
              </div>
              <div class="name">
                {{ person.patient !== null ? person.patient.firstname : '--' }}
                {{ person.patient !== null ? person.patient.middlename : '--' }}
              </div>
            </div>
            <div class="buttons-client">
              <div class="top-btn">
                <div class="date-time black hide-old">
                  {{ formatDate(person.createdate) }}
                </div>
                <span class="bold hide-new">{{ person.job }}</span>
                <ul class="pay-box-btn hide-new">
                  <li>
                    <a
                      href="#"
                      class="full-flex-center brand-hover with-border"
                    ><i class="ico-17" /></a>
                  </li>
                </ul>
              </div>
              <ul class="bottom-btn pay-box-btn">
                <li class="tooltip-wrapper">
                  <router-link
                    :to="'/patients/' + person.patient.id + '/info'"
                    class="full-flex-center brand-hover with-border"
                  >
                    <i class="ico-21" />
                  </router-link>
                  <div class="tooltip">
                    {{ $t('Перейти в картку пацієнта') }}
                  </div>
                </li>
                <!--                <li class="tooltip-wrapper">-->
                <!--                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-35"></i></a>-->
                <!--                  <div class="tooltip">Завантажити договір</div>-->
                <!--                </li>-->
                <!--                <li class="tooltip-wrapper">-->
                <!--                  <a href="#" class="full-flex-center brand-hover with-border"><i class="ico-48"></i></a>-->
                <!--                  <div class="tooltip">Завантажити акт</div>-->
                <!--                </li>-->
              </ul>
            </div>
          </div>
          <div class="sum-count before-border">
            <div class="caption-grey">
              {{ $t('Сума') }}
            </div>
            <div class="sum-counter black bold">
              {{ sm = person.summ - person.paidincash }}
            </div>
            <div class="caption-grey">
              {{ $t('Знижка') }}
            </div>
            <div class="discount black bold">
              {{ person.discount_amount }}грн
            </div>
          </div>
          <div class="discount-wr">
            <div class="caption-grey">
              {{ $t('Сума зі знижкою') }}
            </div>
            <div class="counter">
              {{ sm - person.discount_amount }}
            </div>
          </div>
          <div class="last-block-pay hide-new">
            <div class="btn-2 before-border">
              <ul class="pay-box-btn">
                <li class="tooltip-wrapper">
                  <a
                    href="#"
                    class="full-flex-center brand-hover with-border"
                  ><i class="ico-24" /></a>
                  <div class="tooltip">
                    {{ $t('Показати всі рахунки пацієнта') }}
                  </div>
                </li>
                <li class="tooltip-wrapper">
                  <a
                    href="#"
                    class="full-flex-center brand-hover with-border"
                  ><i class="ico-25" /></a>
                  <div class="tooltip">
                    {{ $t('Показати всі рахунки пацієнта') }}
                  </div>
                </li>
              </ul>
            </div>
            <div class="balance">
              <div class="caption-grey">
                {{ $t('Баланс') }}
              </div>
              <div class="sum-counter black bold">
                {{ person.balance }}
              </div>
            </div>
            <div class="wrap-money-btn">
              <a
                href="#"
                class="take-money-btn brand-hover with-border"
              >{{ $t('Вивести гроші') }}</a>
            </div>
          </div>
          <div class="last-block-new hide-old">
            <a
              href="#"
              class="btn-create-bill bold brand-hover with-border"
              @click.prevent="createOrder(index)"
            >{{ $store.state.user.user.role === 3 || $store.state.user.user.role === 7 ? $t('Створити рахунок') : $t('Переглянути рахунок') }}</a>
            <div class="right-b">
              <div class="caption-grey">
                {{ $t('Сума') }}
              </div>
              <div class="sum-counter black bold">
                {{ sm - person.discount_amount }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-marge-small">
        <span>{{ $t('Всього без знижок') }}:</span>
        <div class="number">
          {{ total() }}
        </div>
        <span>{{ $t('Всього зі знижками') }}: </span>
        <div class="number brand-color">
          {{ totalDiscount() }}
        </div>
        <span>{{ $t('Всього незакритих процедур') }}:</span>
        <div class="number red-color">
          {{ totalDiscount() }}
        </div>
      </div>
    </div>

    <el-dialog
        v-model="$store.state.modal.createOrder"
        width="960px"
        :title="$t('Рахунок пацієнта №') + Invoice.Current.Id"
        class="modal-custom"
    >
      <form @submit.prevent="preSavePay()">
        <div class="box-blue-modal clearfix">
          {{ Invoice.Current.Patient }}
          <span class="right brand-color">Баланс: {{ calcBalance }}</span>
        </div>
        <div class="body-modal form-long">
          <div class="form-box-green" style="justify-content: flex-end">
            <label class="label-checkbox">
              <input type="checkbox" @change="checkAll"> {{$t('Вибрати всі')}}
            </label>
          </div>
          <div
            v-for="person2 in Invoice.Items"
            :key="person2.id"
            class="form-box-green"
          >
            <div class="description">
              <a
                href="#"
                class="remove-link"
                @click.prevent="deleteItemInvoice(person2.id)"
              ><i class="ico-20" /></a>
              <div class="name">
                {{ person2.name }}
              </div>
              <div class="doc-name-small">
                {{ person2.doc }} | {{ person2.createdate }}
              </div>
            </div>
            <div class="right-box">
              <span>{{ person2.proc_count }} ×</span>
              <span>{{ person2.price }}</span>
              <input
                v-if="$root.user.role === 3 || $root.user.role === 7"
                v-model="person2.discount"
                type="text"
                class="custom-input"
                style="width: 60px"
              >
              <span v-if="$root.user.role === 3 || $root.user.role === 7">= {{
                (person2.price * person2.proc_count) - person2.discount
              }}</span>
            </div>
            <label
              v-if="$root.user.role === 3 || $root.user.role === 7"
              class="label-checkbox"
            ><input
               type="checkbox"
               :value="person2.id"
               @change="addToPay(person2)"
               name="selected-items"
             >
              {{ $t('Оплачено') }}</label>
          </div>
          <a
            href="#"
            class="modal-main-link"
            @click.prevent="$modal.show('addService')"
          >+
            {{ $t('Додати послугу') }}</a>
        </div>
        <div
          class="footer-modal manager-footer"
          style="align-items: flex-start"
        >
          <div v-if="user.role === 3 || user.role === 7">
            <div
              v-show="Invoice.Invoices.type !== '3'"
              style="display: flex;margin-bottom: 5px;align-items: center"
            >
              <div class="pay-finish-t">
                {{ $t('Оплачено') }}:
              </div>
              <input
                v-model="Invoice.Invoices.amount"
                type="text"
                class="custom-input box-f-t"
                placeholder="0"
                readonly
              >
            </div>
            <div
              v-show="Invoice.Invoices.type === '3'"
              style="display: flex;margin-bottom: 5px;align-items: center"
            >
              <div class="pay-finish-t">
                {{ $t('Готівкою') }}:
              </div>
              <input
                v-model="Invoice.Invoices.amountCash"
                type="text"
                class="custom-input box-f-t"
                placeholder="0"
                readonly
              >
            </div>
            <div
              v-show="Invoice.Invoices.type === '3'"
              style="display: flex;margin-bottom: 5px;align-items: center"
            >
              <div class="pay-finish-t">
                {{ $t('Карткою') }}:
              </div>
              <input
                v-model="Invoice.Invoices.amountCard"
                type="text"
                class="custom-input box-f-t"
                placeholder="0"
                @input="Invoice.Invoices.amountCash = Invoice.Invoices.amount - Invoice.Invoices.amountCard"
              >
            </div>
          </div>
          <div v-if="user.role === 3 || user.role === 7">
            <div style="display: flex;margin-bottom: 5px;align-items: center">
              <div class="pay-finish-t">
                {{ $t('Знижка') }}:
              </div>
              <input
                v-model="Invoice.Invoices.discount"
                type="text"
                class="custom-input box-f-t"
                placeholder="0"
                readonly
              >
            </div>
            <div style="display: flex;margin-bottom: 5px;align-items: center">
              <div class="pay-finish-t">
                {{ $t('Ітого') }}:
              </div>
              <input
                v-model="Invoice.Invoices.withDiscount"
                type="text"
                class="custom-input box-f-t"
                placeholder="0"
                readonly
              >
            </div>
          </div>
          <button
            v-if="user.role === 3 || user.role === 7"
            class="btn-green"
          >
            {{ $t('Зберегти') }}
          </button>
          <button
            class="btn-red"
            @click.prevent="$modal.hide('createOrder')"
          >
            {{ $t('Скасувати') }}
          </button>
        </div>
      </form>
    </el-dialog>

    <el-dialog
        v-model="$store.state.modal.addService"
        width="960px"
        :title="$t('Додати послугу')"
        class="modal-custom"
    >
      <form @submit.prevent="inMoney">
        <div class="body-modal form-long">
          <div class="line-modal-form">
            <div class="description">
              {{ $t('Послуга') }}
            </div>
            <div class="input-wr">
              <el-select v-model="newInvoiceItem" style="width: 100%" multiple>
                <el-option
                    v-for="item in services"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>
        <div class="footer-modal manager-footer">
          <button
            class="btn-green"
            type="button"
            @click="addItemToInvoice"
          >
            {{ $t('Додати') }}
          </button>
          <button
            class="btn-red"
            @click.prevent="closeAddItem"
          >
            {{ $t('Скасувати') }}
          </button>
        </div>
      </form>
    </el-dialog>

    <el-dialog
        v-model="$store.state.modal.submitPayment"
        width="600px"
        :title="$t('Сума до оплати')"
        class="modal-custom"
    >
      <div class="cash-modal-head">
        <div class="cash-modal-head-title">
          <i
            v-if="paymentStep !== 'step-1'"
            class="ico-66"
            @click="() => {
              if (paymentStep === 'step-2') {
                paymentStep = 'step-1'
              } if (paymentStep === 'step-4') {
                paymentStep = 'step-1'
              } else {
                paymentStep = 'step-2'
              }
            }"
          />
          {{ $t('Сума до оплати') }}: <span
            style="margin-left: 10px"
          >{{ Invoice.Invoices.withDiscount }} {{ $t('грн') }}</span>
        </div>
      </div>
      <div
        v-if="paymentStep === 'step-1'"
        class="cash-modal-body"
      >
        <a
          href="#"
          @click.prevent="selectPayMethod(1)"
        >
          <div>
            <div class="icon"><i class="ico-wallet" /></div>
            <span>{{ $t('Готівка') }}</span>
          </div>
        </a>
        <a
          href="#"
          @click.prevent="selectPayMethod(2)"
        >
          <div>
            <div class="icon"><i class="ico-card" /></div>
            <span>{{ $t('Картка') }}</span>
          </div>
        </a>
        <a
          href="#"
          @click.prevent="payPP"
        >
          <div>
            <div class="icon"><i class="ico-invoice" /></div>
            <span>{{ $t('Р/р') }}</span>
          </div>
        </a>
        <a
          href="#"
          @click.prevent="pay2Method"
        >
          <div>
            <div class="icon"><i class="ico-cashless" /></div>
            <span>{{ $t('Розділити') }}</span>
          </div>
        </a>
      </div>
      <div
        v-if="paymentStep === 'step-2'"
        class="cash-modal-body"
      >
        <div class="need-ticket">
          <div class="icon">
            <i class="ico-65" />
          </div>
          <div class="title">
            {{ $t('Потрібен чек?') }}
          </div>
        </div>
      </div>
      <div
        v-if="paymentStep === 'step-3'"
        class="cash-modal-body"
      >
        <div class="mod-body">
          <div class="form-group">
            <label>{{ $t('Виберіть ФОП-а') }}</label>
            <el-select
                v-model="currentCashier"
                class="m-2"
                size="large"
                filterable
                v-if="$store.state.user.user.role === 3 || $store.state.user.user.role === 7"
            >
              <el-option
                  v-for="item in cashiers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div
        v-if="paymentStep === 'step-2'"
        class="footer-modal"
      >
        <button
          class="btn-confirm"
          type="button"
          @click="() => {
            Invoice.Invoices.has_check = true
            paymentStep = 'step-3'
          }"
        >
          {{ $t('так') }}
        </button>
        <button
          class="btn-cancel"
          type="button"
          @click="() => {
            Invoice.Invoices.has_check = false
            inMoney()
          }"
        >
          {{ $t('ні') }}
        </button>
      </div>
      <div
        v-if="paymentStep === 'step-4'"
        class="cash-modal-body"
      >
        <div class="mod-body">
          <div class="form-group">
            <label>{{ $t('Сума готівкою') }}</label>
            <input
              v-model="Invoice.Invoices.amountCash"
              type="text"
              class="custom-input form-control"
              disabled
            >
          </div>
          <div class="form-group">
            <label>{{ $t('Сума карткою') }}</label>
            <input
              v-model="Invoice.Invoices.amountCard"
              type="text"
              class="custom-input form-control"
              placeholder="0"
              @input="Invoice.Invoices.amountCash = Invoice.Invoices.amount - Invoice.Invoices.amountCard"
            >
          </div>
        </div>
      </div>
      <div
        v-if="paymentStep === 'step-3'"
        class="footer-modal"
      >
        <button
          class="btn-confirm"
          type="button"
          @click="inMoney()"
        >
          {{ $t('Оплатити') }}
        </button>
        <button
          class="btn-cancel"
          type="button"
          @click="hidePayModal()"
        >
          {{ $t('Скасувати') }}
        </button>
      </div>
      <div
        v-if="paymentStep === 'step-4'"
        class="footer-modal"
      >
        <button
          class="btn-confirm"
          type="button"
          @click="paymentStep = 'step-2'"
        >
          {{ $t('Оплатити') }}
        </button>
        <button
          class="btn-cancel"
          type="button"
          @click="hidePayModal()"
        >
          {{ $t('Скасувати') }}
        </button>
      </div>
    </el-dialog>

    <!-- Друк фіскального чеку -->
    <div
      id="printMe"
      style="white-space: pre;display: none"
    >
      <div
        id="check"
        v-html="printContent"
      />
    </div>

    <!-- Вибір касира для відкриття або закриття зміни -->
    <el-dialog
      v-model="$store.state.modal.openShift"
      :title="$t('Виберіть касира(-ів)')"
      width="500px"
      class="modal-custom"
    >
      <div
        v-for="cashier in cashiers"
        :key="cashier.id"
        class="line-modal-form"
      >
        <label><input
          v-model="arrCashier"
          type="checkbox"
          :value="cashier.id"
        > {{ cashier.name }} <span
          v-if="cashier.status === 1"
          style="color: green"
        >({{ $t('зміну відкрито') }})</span></label>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeShift">{{ $t('Закрити') }}</el-button>
          <el-button
            type="success"
            @click.prevent="openShift"
          >{{ $t('Відкрити') }}</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Друк локального чеку -->
    <div
      id="printCheck"
      style="display: none"
    >
      <img
        :src="checkImage"
        style="width: 48mm"
      >
    </div>

    <div id="printMeLocal" style="display: none">
      <div class="ticket" style="border-top: 2px solid black">
        <div class="address" style="font-size: 12px;font-weight: 600;border-bottom: 4px solid black;line-height: 13px;margin-bottom: 3px;padding-bottom: 2px">
          <div>{{localTicket.clinic.name}}</div>
          <div>{{localTicket.clinic.address}}</div>
          <div>ІД {{localTicket.clinic.code}}</div>
        </div>
        <div class="lineItem" style="padding: 0;font-size: 12px;font-weight: 600;line-height: 13px;margin-bottom: 6px;" v-for="item in localTicket.items">
          <div>{{ item.amount }} x {{ item.price }}</div>
          <div v-if="Number(item.discount) > 0">Знижка: {{ item.discount }}%</div>
          <div>{{ item.name }}
            <span>{{ ((Number(item.price) - Math.ceil(Number(item.price) * Number(item.discount) / 100))*Number(item.amount)).toFixed(2) }}&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
          <div style="clear: both"></div>
        </div>
        <div class="pay" style="margin-top: -4px;padding: 0 0 3px 0;border-top: 2px solid black;font-size: 12px;font-weight: 600" v-if="localTicket.cash !== '0.00'">
          <div>Готівка</div>
          <div>{{ localTicket.cash }} ГРН</div>
        </div>
        <div class="pay" style="margin-top: -4px;padding: 0 0 3px 0;border-top: 2px solid black;font-size: 12px;font-weight: 600" v-if="localTicket.card !== '0.00'">
          <div>Картка</div>
          <div>{{ localTicket.card }} ГРН</div>
        </div>
        <div class="pay" style="margin-top: -4px;padding: 0 0 3px 0;border-top: 2px solid black;font-size: 12px;font-weight: 600" v-if="localTicket.pp !== '0.00'">
          <div>P/p</div>
          <div>{{ localTicket.pp }} ГРН</div>
        </div>
        <div class="total" style="border-bottom: 4px solid black">
          <div>СУМА</div>
          <div>{{ localTicket.total }} ГРН</div>
        </div>
        <div class="number" style="padding: 0;font-size: 13px;font-weight: 700;margin-top: 3px;border: 0;">№ {{ localTicket.number }}</div>
        <div class="date" style="font-size: 12px;font-weight: 600">
          <div>{{ localTicket.date }}</div>
          <div>{{ localTicket.time }}</div>
        </div>
        <div style="text-align: center">
          <img src="../../assets/images/qr.png" width="60%">
        </div>
        <span style="font-weight: 600;font-size: 12px;display: block;text-align: center">ОНЛАЙН</span>
        <div style="display: flex;justify-content: space-between;font-size: 12px;line-height: 13px;">
          <div>ФН ПРРО</div>
          <div>4000372803</div>
        </div>
        <div style="text-align: center;font-size: 12px;line-height: 13px;">ФІСКАЛЬНИЙ ЧЕК</div>
        <div style="text-align: center;font-weight: 700;padding-bottom: 2px;font-size: 16px;line-height: 17px;">checkbox</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {ElMessage} from "element-plus";
import axios from "axios";
import {store} from "@/store";

export default {
  name: 'CashFlowAccounts',
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      date: new Date(),
      articles: [],
      articlesOriginal: [],
      Invoice: {
        Items: [],
        Current: {
          Patient: '',
          Discount: 0,
          Id: 0,
          Date: '',
          Balance: 0
        },
        Invoices: {
          type: 1,
          amount: 0,
          id: 0,
          date: this.currentDate,
          has_check: false,
          items: [],
          amountCard: 0,
          amountCash: 0
        }
      },
      currentDate: new Date(),
      services: [],
      newInvoiceItem: [],
      calcBalance: 0,
      open: false,
      currentCashier: 0,
      cashiers: [],
      printContent: '',
      arrCashier: [],
      resizeInvoice: {
        cash: 0,
        card: 0,
        invoice_id: 0,
        total: 0
      },
      checkImage: '',
      paymentStep: 'step-1',
      typePayments: {
        1: 'готівкою',
        2: 'карткою',
        4: 'р/р',
        3: 'розділений'
      },
      localTicket: {
        card: 0,
        cash: 0,
        date: new Date(),
        items: [],
        time: '00:00',
        total: 0,
        number: '',
        clinic: {
          name: '',
          address: '',
          code: '',
        }
      }
    }
  },
  async created() {
    await this.$http.get('/money/get-active')
        .then(response => {
          this.articles = response.data.invoices
          this.articlesOriginal = response.data.invoices
          this.cashiers = response.data.cashier
          this.services = response.data.healthproc
        })
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    total() {
      let total = 0
      this.articles.map(e => {
        let s = e.summ - e.paidincash
        total += s
      })
      return total
    },
    totalDiscount() {
      let total = 0
      this.articles.map(e => {
        let s = e.summ - e.paidincash
        total += s - (s * e.discount_amount) / 100
      })
      return total
    },
    toggleModal(name, show) {
      this.modal[name] = show
    },
    preSavePay() {
      this.paymentStep = 'step-1'
      this.$modal.show('submitPayment')
    },
    pay2Method() {
      this.selectPayMethod(3)
      this.paymentStep = 'step-4'
    },
    payPP() {
      this.selectPayMethod(4)
      this.inMoney()
    },
    hidePayModal() {
      this.$modal.hide('submitPayment')
    },
    selectPayMethod(type) {
      this.Invoice.Invoices.type = type
      this.paymentStep = 'step-2'
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    startResizeInvoice(id, total) {
      this.resizeInvoice.cash = total
      this.resizeInvoice.id = id
      this.resizeInvoice.total = total
      this.$modal.show('resizeInvoice')
    },
    completeResizeInvoice() {
      const all = Number(this.resizeInvoice.cash) + Number(this.resizeInvoice.card)
      if (all !== Number(this.resizeInvoice.total)) {
        alert('Не вірно розподілена сумма')
      } else {
        this.$http.post('/money/resize-invoice', this.resizeInvoice).then(result => {
          this.Invoice.Items = result.data
          this.$modal.hide('resizeInvoice')
        }).catch(error => {
          alert(error.message)
        })
      }
    },
    closeResizeInvoice() {
      this.$modal.hide('resizeInvoice')
      this.resizeInvoice = {
        cash: 0,
        card: 0,
        invoice_id: 0
      }
    },
    filterItem(e) {
      const q = (e.target.value).toLowerCase()
      this.articles = this.articlesOriginal.filter(e => {
        if (e.patient) {
          const lastname = (e.patient.lastname).toLowerCase()
          const firstname = (e.patient.firstname).toLowerCase()
          const middlename = (e.patient.middlename).toLowerCase()
          return lastname.indexOf(q) !== -1 || firstname.indexOf(q) !== -1 || middlename.indexOf(q) !== -1
        } else {
          return false
        }
      })
    },
    funcCalcBalance() {
      this.calcBalance = ((this.Invoice.Current.Balance - (this.Invoice.Current.Balance * (this.Invoice.Current.Discount / 100)))).toFixed(2)
    },
    createOrder(id) { // модалка для створення рахунку
      this.Invoice.Invoices.items = []
      let inv = this.articles[id]
      this.$http.get('/money/item-invoice', {
        params: {
          id: inv.id
        }
      })
          .then(response => {
            this.Invoice.Current.Patient = inv.patient.fullname
            this.Invoice.Current.Id = inv.id
            this.Invoice.Invoices.id = inv.id
            this.Invoice.Current.Discount = inv.discount_amount
            this.Invoice.Current.Date = inv.createdate
            this.Invoice.Current.Balance = inv.paidincash - inv.summ
            this.Invoice.Items = response.data
            this.Invoice.Invoices.withDiscount = 0
            this.Invoice.Invoices.discount = 0
            this.$modal.show('createOrder')
            this.funcCalcBalance()
          })
    },

    /**
     * Збереження оплати
     */
    inMoney() {
      let self = this
      const arr = [`<li>тип оплати - <strong>${this.typePayments[this.Invoice.Invoices.type]}</strong></li>`, `<li>чек - <strong>${this.Invoice.Invoices.has_check ? 'так' : 'без чеку'}</strong></li>`]
      if (this.Invoice.Invoices.has_check) {
        const fop = this.cashiers.filter(cash => {
          return cash.id === this.currentCashier
        })
        arr.push(`<li>ФОП - <strong>${fop.shift().name}</strong></li>`)
      }
      this.$swal({
        icon: 'question',
        title: 'Буде здійснено наступний платіж:',
        html: `<ul class="confirm-list">${arr.join('')}</ul>`,
        showDenyButton: true,
        confirmButtonText: self.$t('Так, все вірно'),
        denyButtonText: self.$t('Скасувати'),
      }).then((result) => {
        if (result.isConfirmed) {
          const fullDataCashier = this.cashiers.find(cash => {
            return Number(cash.id) === Number(this.currentCashier);
          })
          if (this.Invoice.Invoices.type === 2 && fullDataCashier.merchant_id !== null && fullDataCashier.merchant_id !== '') {
            store.commit("setStatus", "load");
            axios.post('http://localhost:5000/terminal', {"command": {
              "method": "Purchase",
              "step": 0,
              "params": {"amount": String(this.Invoice.Invoices.amount), "discount": "", "merchantId": fullDataCashier.merchant_id}
            }}, {
              headers: {
                "Content-Type": "application/json"
              }
            }).then(response => {
              store.commit("setStatus", null);
              let str = response.data.message.replaceAll('\n', '');
              str = str.replaceAll('\"', '"');
              str = str.replace('\u0000', '');
              const terminal = JSON.parse(str);
              if (Number(terminal.params.responseCode) >= 1000) {
                ElMessage({
                  message: this.$t('Помилка оплати через термінал. ') + terminal.errorDescription,
                  type: 'error',
                })
              } else {
                this.$http.post('/money/in-money', {
                  invoice: this.Invoice.Invoices,
                  discount: this.Invoice.Current.Discount,
                  cashier_id: this.currentCashier,
                  terminal
                })
                    .then(result => {
                      if (result.data.checkId) {
                        setTimeout(() => {
                          this.toDataURL(`https://api.checkbox.in.ua/api/v1/receipts/${result.data.checkId}/png?paper_width=58`, function (result) {
                            self.checkImage = result
                            setTimeout(() => {
                              self.$htmlToPaper('printCheck')
                            }, 500)
                          })
                        }, 1000)
                      } else if (result.data.localCheckId) {
                        this.localTicket = result.data.localCheck
                        setTimeout(() => {
                          this.$htmlToPaper('printMeLocal')
                        }, 500)
                      }
                      this.articles = result.data.invoices
                      this.hidePayModal()
                      this.$modal.hide('createOrder')
                      ElMessage({
                        message: this.$t('Кошти внесено'),
                        type: 'success',
                      })
                      this.currentCashier = 0
                      this.Invoice.Invoices = {
                        type: 1,
                        amount: 0,
                        id: 0,
                        date: this.currentDate,
                        has_check: false,
                        items: [],
                        amountCash: 0,
                        amountCard: 0,
                        withDiscount: 0
                      }
                    })
                    .catch(error => {
                      console.log('[Error]', error)
                      ElMessage({
                        message: this.$t('Помилка внесення коштів'),
                        type: 'error',
                      })
                    })
              }
            }).catch(error => {
              store.commit("setStatus", null);
              console.error('[Error]', error)
            })
          } else {
            this.$http.post('/money/in-money', {
              invoice: this.Invoice.Invoices,
              discount: this.Invoice.Current.Discount,
              cashier_id: this.currentCashier,
            })
                .then(result => {
                  if (result.data.checkId) {
                    setTimeout(() => {
                      this.toDataURL(`https://api.checkbox.in.ua/api/v1/receipts/${result.data.checkId}/png?paper_width=58`, function (result) {
                        self.checkImage = result
                        setTimeout(() => {
                          self.$htmlToPaper('printCheck')
                        }, 500)
                      })
                    }, 1000)
                  } else if (result.data.localCheckId) {
                    this.localTicket = result.data.localCheck
                    setTimeout(() => {
                      this.$htmlToPaper('printMeLocal')
                    }, 500)
                  }
                  this.articles = result.data.invoices
                  this.hidePayModal()
                  this.$modal.hide('createOrder')
                  ElMessage({
                    message: this.$t('Кошти внесено'),
                    type: 'success',
                  })
                  this.currentCashier = 0
                  this.Invoice.Invoices = {
                    type: 1,
                    amount: 0,
                    id: 0,
                    date: this.currentDate,
                    has_check: false,
                    items: [],
                    amountCash: 0,
                    amountCard: 0,
                    withDiscount: 0
                  }
                })
                .catch(error => {
                  console.log('[Error]', error)
                  ElMessage({
                    message: this.$t('Помилка внесення коштів'),
                    type: 'error',
                  })
                })
          }
        }
      })
    },
    filtering() {
      this.$http.get('/money/get-active', {
        params: {
          date: this.currentDate
        }
      })
          .then(response => {
            this.articles = response.data.invoices
            this.articlesOriginal = response.data.invoices
          })
    },
    deleteItemInvoice(id) { // видалення послуги з інвойсу
      let removeItem = this.Invoice.Invoices.items.find(e => {
        return Number(e.id) === Number(id)
      })
      if (removeItem === undefined) {
        if (confirm('Видалити позицію?')) {
          this.$http.get('/money/remove-invoice-item', {
            params: {
              id
            }
          }).then(result => {
            this.Invoice.Items = result.data.items
            this.Invoice.Current.Balance = Number(result.data.total) * -1
            this.funcCalcBalance()
          }).catch(error => {
            alert(error.data.response)
          })
        }
      } else {
        this.$root.alerts('error', 'Не можливо видалити позицію, яку ви відмітили як "Оплачено". Зніміть відмітку і тоді видаліть')
      }
    },
    addItemToInvoice() { // додавання послуг
      if (this.newInvoiceItem.length === 0) {
        alert('Виберіть хоча б одну послугу!')
      } else {
        this.$http.post('/tasks/add-item-to-invoice', {
          services: this.newInvoiceItem,
          invoice_id: this.Invoice.Current.Id
        }).then(result => {
          this.Invoice.Items = result.data.items
          this.Invoice.Current.Balance = Number(result.data.total) * -1
          this.closeAddItem()
          this.funcCalcBalance()
        }).catch(error => {
          console.log('[Log error]', error)
        })
      }
    },
    closeAddItem() {
      console.log('[Log]', 'Close modal')
      this.newInvoiceItem = []
      this.$modal.hide('addService')
    },
    changeDiscount() {
      this.Invoice.Current.Discount = event.target.value
    },
    addToPay(obj) {
      if (event.target.checked) {
        this.Invoice.Invoices.amount += Number(obj.price) * Number(obj.proc_count)
        this.Invoice.Invoices.amountCash += Number(obj.price) * Number(obj.proc_count)
        this.Invoice.Invoices.discount += Number(obj.discount)
        this.Invoice.Invoices.withDiscount += (Number(obj.price) * Number(obj.proc_count)) - Number(obj.discount)
        this.Invoice.Invoices.items.push(obj)
      } else {
        this.Invoice.Invoices.amountCash -= (Number(obj.price) * Number(obj.proc_count))
        this.Invoice.Invoices.amount -= (Number(obj.price) * Number(obj.proc_count))
        this.Invoice.Invoices.discount -= Number(obj.discount)
        this.Invoice.Invoices.withDiscount -= (Number(obj.price) * Number(obj.proc_count)) - Number(obj.discount)
        this.Invoice.Invoices.items = this.Invoice.Invoices.items.filter(e => Number(e.id) !== Number(event.target.value))
      }
    },
    checkAll() {
      const checkboxes = document.getElementsByName('selected-items');
      for(let i=0, n=checkboxes.length;i<n;i++) {
        checkboxes[i].checked = event.target.checked;
      }
      if (event.target.checked) {
        this.Invoice.Items.map(obj => {
          this.Invoice.Invoices.amount += Number(obj.price) * Number(obj.proc_count)
          this.Invoice.Invoices.amountCash += Number(obj.price) * Number(obj.proc_count)
          this.Invoice.Invoices.discount += Number(obj.discount)
          this.Invoice.Invoices.withDiscount += (Number(obj.price) * Number(obj.proc_count)) - Number(obj.discount)
          this.Invoice.Invoices.items.push(obj)
        })
      } else {
        this.Invoice.Items.map(obj => {
          this.Invoice.Invoices.amountCash -= (Number(obj.price) * Number(obj.proc_count))
          this.Invoice.Invoices.amount -= (Number(obj.price) * Number(obj.proc_count))
          this.Invoice.Invoices.discount -= Number(obj.discount)
          this.Invoice.Invoices.withDiscount -= (Number(obj.price) * Number(obj.proc_count)) - Number(obj.discount)
          this.Invoice.Invoices.items = this.Invoice.Invoices.items.filter(e => Number(e.id) !== Number(event.target.value))
        })
      }
    },
    closeShift() {
      this.$http.post('/money/shift', {
        'action': 'close',
        'cashiers': this.arrCashier
      }).then((response) => {
        this.printContent = ''
        this.arrCashier = []
        this.cashiers = response.data.users
        response.data.zreport.map(ch => {
          this.printContent += ch
        })
        setTimeout(() => {
          this.$htmlToPaper('printMe')
        }, 500)
        this.open = false
        this.$toastr.s('Зміну закрито')
      }).catch(error => {
        console.log('[Error]', error)
        this.$toastr.e('Помилка закриття зміни. Зверніться до адміністратора')
      })
    },
    openShift() {
      this.$http.post('/money/shift', {
        'action': 'open',
        'cashiers': this.arrCashier
      }).then((response) => {
        this.cashiers = response.data
        this.$toastr.s("Зміну відкрито")
      }).catch(error => {
        this.$toastr.e(error.response.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
